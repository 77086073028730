import Home from "../components/home/Home";
import AboutMe from "../components/aboutMe/AboutMe";
import Resume from "../components/resume/Resume";
import ContactMe from "../components/contactMe/ContactMe"
import Footer from "../components/footer/Footer";

export const TOTAL_SCREENS = [
    {
        screen_name: "Home",
        component: Home
    },
    {
        screen_name: "About Me",
        component: AboutMe
    },
    {
        screen_name: "Resume",
        component: Resume
    },
    {
        screen_name: "Contact Me",
        component: ContactMe
    },
    {
        component: Footer
    },
];

export const GET_SCREEN_INDEX = (screen_name) => {
    if (!screen_name) return -1
    for(let i = 0; i < TOTAL_SCREENS.length; i++){
        if(TOTAL_SCREENS[i].screen_name === screen_name) return i;
    }
    return -1;
};